/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

const billingDetail = `
  billingDetail {
    paymentMethod
    cardType
    cardNumber
    totalCharge
    promotionCode
    promotionDiscountAmount
    promotionDiscountPercent
    shippingCharge
    serviceCharge
    tax
    surcharge
    orderDate
    thirdPartyType
    giftCardPayments {
      giftCardNumber
      giftCardAmount
    }
    billingPersonProfile {
      customerId
      firstName
      lastName
      businessName
      emailAddress
      addressType
      attentionText
      recipientEnable
      recipientEnableReason
      recId
      customerFullName
      recipientFullName
      address {
        addressLine1
        addressLine2
        addressLine3
        cityName
        state
        countryCode
        zipcode
        addressType
        attentionText
      }
      phone {
        type
        number
        extension
      }
    }
  }
`;

const recipientDetail = `
  recipientDetail {
    customerId
    firstName
    lastName
    businessName
    emailAddress
    addressType
    attentionText
    recipientEnable
    recipientEnableReason
    recId
    customerFullName
    recipientFullName
    address {
      addressLine1
      addressLine2
      addressLine3
      cityName
      state
      countryCode
      zipcode
      addressType
      attentionText
    }
    phone {
      type
      number
      extension
    }
  }
`;

const itemDetailList = `
  itemDetailList {
    cardMessage
    productName
    productSku
    productBrand
    price
    quantity
    deliveryDate
    shippingMethod
    personalization
    productName
    itemDiscountAmount
    itemTaxAmount
    itemServiceChargeAmount
    itemShippingChargeAmount
    shippingTax
    shipDate
    dtwName
    dtwCharge
    itemType
    sfItemType
    addonType
    parentLineNumber
    childLineNumber
    flexDate
    flexMessage
    flexGuaranteedFlag
    prdImgCtg
    prdImgTmn
    arrivalStartDate
    arrivalEndDate
    cardMsgEnable
    cardMsgEnableReason
    deliveryDateEnable
    deliveryDateEnableReason
    itemLineId
    sequenceNumber
    recipientSeqNumber
    seoUrl
    subItemType
    subscriptionType
    interval
    duration
    fulfillSku
    promCode
  }
`;
export const getOrdersByEmailIdQL = (email) => `
  {
    getOrdersByEmailId(emailId: "${email}") {
      brandId
      emailId
      orders {
        orderNumber
        intOrderNumber
        orderPlaceddate
        customerFirstName
        customerLastName
        modifyOrder
        parentOrderNumber
        items {
          brandId
          productSku
          productName
          recpFirstName
          recpLastName
          shipDate
          paymentStatus
          estimatedDeliveryDate
          trackingNumber
          itemType
          sfItemType
          addonType
          parentLineNumber
          childLineNumber
          flexDate
          flexMessage
          flexGuaranteedFlag
          prdImgCtg
          prdImgTmn
          arrivalStartDate
          arrivalEndDate
          carrier
          seoUrl
          subscriptionType
          interval
          duration
        }
      }
      error {
        errorCode
        errorMessage
      }
    }
  }
  `;

const orderInfoListFieldsById = `
  actualDeliveryDate
  brandOrderNum
  carrier
  deliverySignature
  exceptionCode
  exceptionDateTime
  exceptionMessage
  intOrderNumber
  orderDate
  orderNumber
  orderType
  phoneNumber
  recipientFirstName
  recipientLastName
  recipientState
  recipientZip
  requestedDeliveryDate
  rescheduledDeliveryDate
  resolutionDesc
  shipDate
  shipState
  shipmentCode
  sourceSystem
  status
  trackingNumber
`;

export const getDetailedOrderByIdQL = (orderNumber) => `
  {
    getDetailedOrderById(orderId: "${orderNumber}") {
      orderId
      token
      error {
        errorCode
        errorMessage
      }
      orderInfoList {
        parentOrderNumber
        ${orderInfoListFieldsById}
        ${billingDetail}
        ${recipientDetail}
        ${itemDetailList}
      }
    }
  }
  `;
export const getDetailedOrderGuestByIdQL = (orderNumber) => `
  {
    getDetailedOrderById(orderId: "${orderNumber}") {
      orderId
      token
      error {
        errorCode
        errorMessage
      }
      orderInfoList {
        parentOrderNumber
        ${orderInfoListFieldsById}
        ${recipientDetail}
        ${itemDetailList}
        ${billingDetail}
      }
    }
  }
  `;

export const getDetailedOrderByIdAndZipcodeQL = (orderNumber, zipCode, billingZipCode) => `
{
  getOrderByIdZipCodeAndBillingZipCode(orderId: "${orderNumber}", zipCode: "${zipCode}", billingZipCode: "${billingZipCode}") {
    orderId
    brandId
    orderLevelStatus
    modifyOrder
    error {
      errorCode
      errorMessage
    }
    orderInfoList {
      actualDeliveryDate
      arrivalEndDate
      arrivalStartDate
      carrier
      customerFirstName
      customerLastName
      deliverySignature
      exceptionCode
      exceptionDateTime
      exceptionMessage
      flexDate
      flexGuaranteedFlag
      flexMessage
      intOrderNumber
      orderDate
      orderNumber
      phoneNumber
      recipientFirstName
      recipientLastName
      recipientZip
      requestedDeliveryDate
      rescheduledDeliveryDate
      resolutionDesc
      shipDate
      shipState
      shipmentCode
      status
      trackingNumber
      ${itemDetailList}
    }
  }
 }`;

export const getProductDetailBySKU = (SKU, brand, environment) => (
  gql`{
  findProductByPartNumber(partNumber: "${SKU}", brand: "${brand}", environment: "${environment}") {
    brand
    brandId
    productType
    id
    categories
    partNumber
    name
    seo{
      url
    }
    prices {
      currency
      type
      value
    }
    shortDescription
    longDescription
    productContents
     productSkus {
      longDescription
      ageVerifyFlag
      sympathyValue
    }
    image {
      name
      path
      snipe
      altText
      additionalImages
    }
    availability {
      deliveryMessage
      productDeliveryType
      __typename
  }
  }
 }`
);

export const getProductDetailByItemNumberQL = (SKU, brand, environment) => (
  `{
      findSkuByPartNumbers(
        brand: "${brand}",
        environment: "${environment}",
        partNumbers: ["${SKU.join('","')}"]
      )
      {
        partNumber
        isDefaultSku
        prices
        {
          type
          value
        }
        availability
        {
          earliestShipDate
          availabilityIndicator
          shipNow
          displayStartDate
          displayEndDate
        }
        parentProduct
        {
          name
          partNumber
          shortDescription
          attributes
          {
            name
            values
            {
              value
              sequence
            }
          }
          seo
          {
            url
          }
          image
          {
            name
            path
          }
          productSkus
          {
            partNumber
            isDefaultSku
            prices
            {
              type
              value
            }
            availability
            {
              earliestShipDate
              availabilityIndicator
              shipNow
            }
          }
        }
      }
    }`
);

export const findProductByPartNumbersQL = (partNumbers, brand, environment) => (
  `{
      findProductByPartNumbers(
        partNumbers: ["${partNumbers.join('","')}"]
        brand: "${brand}"
        environment: "${environment}"
      )
      {
        brand
        brandId
        productType
        id
        partNumber
        isPersonalizable
        __typename
        productSkus {
          partNumber
          ageVerifyFlag
          deliveryIndicator
        availability {
          defaultDeliveryMethod
        }
          personalization {
            personalizationDefDesign
            personalizationTemplate
            indicator
            lines {
                lineNumber
                maxChars
                __typename
            }
            __typename
          }
        }
        seo {
          url
        }
      }
    }`
);

export const findSkusByFullPartNumbersQL = (partNumbers, environment) => (
  `{
      findSkusByFullPartNumbers(
      partNumbers: ["${partNumbers.join('","')}"]
      environment: "${environment}"
    )
    {
      clubItem{
        month
        name
        components{
          name
          month
        }
      }
      partNumber
      productType
      ageVerifyFlag
      deliveryIndicator
      clubType
      prices {
        type
        value
      }
      longDescription
      availability {
        defaultDeliveryMethod
        productDeliveryType
      }
      personalization {
        personalizationDefDesign
        personalizationTemplate
        personalizationType
        indicator
        lines {
          lineNumber
          maxChars
          __typename
        }
        __typename
      }
      parentProduct {
        brand
        brandId
        productType
        id
        partNumber
        isPersonalizable
        shortDescription
        __typename
        seo {
          url
        }
      }
    }
  }`
);

export const findProductRecommendationsSF = (queryName) => (
  `query ${queryName} (
        $brand: String!
        $environment: String!
        $user: UserSF!
        $targeting: TargetingSF!
        $userAttributes: [UserAttributesSF]
    ) {
        productRecommendationsSF(
            brand: $brand,
            environment: $environment,
            user: $user,
            targeting: $targeting,
            userAttributes: $userAttributes,
        ) {
            products{
                partNumber
                name
                seo{ url }
                image{
                    path
                    name
                },
                skuPriceRange{
                    sale{ value },
                    retail{ value }
                },
            }
            campaign
            content
        }
    }`
);
